.my-pagination.type-1{ width:auto !important; bottom:auto !important; position:relative !important; left:50% !important; transform:translateX(-50%); }
.my-pagination.type-1{ margin-top:10px; padding:5px; background-color:rgba(0, 0, 0, .5); border-radius:5px; }
.my-pagination.type-1{ display:inline-flex; align-items:center; justify-content:center; }
.my-pagination.type-1 > .swiper-pagination-bullet{ opacity:1; background-color:rgba(255, 255, 255, 1); width:10px; height:10px; }
.my-pagination.type-1 > .swiper-pagination-bullet-active{ opacity:1; background-color:rgba(255, 35, 57, 0.9); }
/**/
.my-pagination.type-2{ position:relative; margin-top:46px; background-color:#d1d1d2; }
.my-pagination.type-2 > .swiper-pagination-progressbar-fill{ opacity:1; background-color:#1a1a1f; }
/**/
.my-pagination.type-3{ color:#d1d1d2; }
.my-pagination.type-3 > .swiper-pagination-current{ color:white; }
/**/
.my-pagination.type-4{ color:#8c8c8f; user-select:none; }
.my-pagination.type-4 > .swiper-pagination-current{ color:black; }
.my-navigation.type-4{ cursor:pointer; text-align:center; background-repeat:no-repeat; background-position:center; height:24px; }
.my-navigation.type-4.prev{ font-size:0; background-image:url(@/assets/icon/icon-10-01.svg); }
.my-navigation.type-4.prev:hover{ font-size:0; background-image:url(@/assets/icon/icon-10-01-active.svg); }
.my-navigation.type-4.next{ font-size:0; background-image:url(@/assets/icon/icon-10-02.svg); }
.my-navigation.type-4.next:hover{ font-size:0; background-image:url(@/assets/icon/icon-10-02-active.svg); }
/**/
.my-navigation.type-1{ user-select:none; cursor:pointer; text-align:center; background-repeat:no-repeat; background-position:center; height:24px; }
.my-navigation.type-1.prev{ font-size:0; background-image:url(@/assets/icon/icon-08-01.svg); }
.my-navigation.type-1.prev:hover{ font-size:0; background-image:url(@/assets/icon/icon-08-01-active.svg); }
.my-navigation.type-1.next{ font-size:0; background-image:url(@/assets/icon/icon-08-02.svg); }
.my-navigation.type-1.next:hover{ font-size:0; background-image:url(@/assets/icon/icon-08-02-active.svg); }
/**/
.SWIPER-1{ }
.SWIPER-1 .swiper-slide.swiper-slide-active .TITLE,
.SWIPER-1 .swiper-slide.swiper-slide-active + .swiper-slide .TITLE{opacity:1;}