:root{
  --app-min-width-MOBILE:360px;
  --app-min-width-DESKTOP:1280px;
  --app-contents-padding-MOBILE:20px;
  --body-background-color:#1a1e33;
  --header-height-MOBILE:94px;
  --header-height-DESKTOP:112px;
  /**/
  --mobile-width-limit:320px;
  /**/
  --neutral-90:#1a1a1f;
  --neutral-70:#5f5f62;
  --neutral-50:#8c8c8f;
  --neutral-30:#d1d1d2;
  --neutral-20:#e1e1e1;
  --neutral-10:#f8f8f8;
  /**/
  --primary-100:#43d2ff;
  --primary-80:#62d9ff;
  --primary-60:#84e2ff;
  --primary-40:#a9eaff;
  --primary-20:#ccf3ff;
  --primary-10:#ebfaff;
  /**/
  --form-wran:#ffd464;
}