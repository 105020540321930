@import "var.css";
@import "@/assets/font/Pretendard.css";
@import "@/assets/font/work-sans-v18-latin";
@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base{
}
@layer components{
}
@layer utilities{
}

/**/
.RR{ outline:1px dashed red; box-shadow:0px 0px 10px cornflowerblue; }
.RRR > *{ outline:1px dashed red; box-shadow:0px 0px 10px cornflowerblue; }
.ONLY-MOBILE{
  @apply xl:hidden;
}
.ONLY-DESKTOP{
  @apply max-xl:hidden;
}
.NO-SCROLL-BAR {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.NO-SCROLL-BAR::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}
html, body, div, h1, h2, h3, h4, h5, h6, th, td, input, textarea, select, button{ font-family:"Pretendard", "Work Sans", "Malgun Gothic", Tahoma, Dotum, "돋움", Arial, sans-serif; word-break:keep-all; }
.Work-Sans{ font-family:"Work Sans", "Malgun Gothic", Tahoma, Dotum, "돋움", Arial, sans-serif; word-break:keep-all; }
address{ font-style:normal; font-weight:400; }
section{}
/*section > .INSIDE{ padding-left:20px; padding-right:20px; margin-left:auto; margin-right:auto; }*/
/*section > .INSIDE{ max-width:360px; }*/
section > .INSIDE{ padding-left:var(--app-contents-padding-MOBILE); padding-right:var(--app-contents-padding-MOBILE); }
/* xl 이상 화면*/
@media screen and (min-width:1280px){
  /*section > .INSIDE{ padding-left:81px; padding-right:81px; }*/
  section > .INSIDE{ padding-left:0; padding-right:0; }
  section > .INSIDE{ max-width:none; width:1280px; margin-left:auto; margin-right:auto; }
}
/******************************************
  TEMPLATE
*******************************************/
.H1{ color:black; font-weight:600; font-size:72px; line-height:76px; }
.H2{ color:black; font-weight:600; font-size:64px; line-height:68px; }
.H3{ color:black; font-weight:600; font-size:48px; line-height:56px; }
.H4{ color:black; font-weight:600; font-size:36px; line-height:44px; }
.H5{ color:black; font-weight:600; font-size:32px; line-height:42px; }
.H6{ color:black; font-weight:600; font-size:24px; line-height:36px; }
.H7{ color:black; font-weight:600; font-size:20px; line-height:28px; }
.H8{ color:black; font-weight:600; font-size:16px; line-height:24px; }
/**/
.BTN{
  display:inline-flex; justify-content:center; align-items:center;
  padding-left:16px; padding-right:16px; height:48px;
  font-weight:600; font-size:14px;
  box-sizing:border-box; border-width:1px; border-style:solid;
}
.BTN.BTN-PRIMARY{
  background-color:var(--primary-100); color:var(--neutral-90); border-color:var(--primary-100);
}
.BTN.BTN-BLACK{
  background-color:var(--neutral-90); color:white; border-color:var(--neutral-90);
}
.BTN.BTN-WHITE{
  background-color:white; color:var(--neutral-90); border-color:white;
}
.BTN.BTN-BLACK-O{
  background-color:transparent; color:var(--neutral-90); border-color:var(--neutral-90);
}
.BTN.BTN-WHITE-O{
  background-color:transparent; color:white; border-color:white;
}

.BTN-TYPE-0{ --btn-hei:32px; }
.BTN-TYPE-0{ display:inline-block; background-color:rgba(255, 255, 255, .2); padding-left:calc(var(--btn-hei) / 2); padding-right:calc(var(--btn-hei) / 2); border-radius:calc(var(--btn-hei) / 2); }
.BTN-TYPE-0{ font-weight:500; font-size:14px; line-height:32px; }
.BTN-TYPE-0:after{
  display:inline-block; content:""; vertical-align:middle; background-repeat:no-repeat; background-size:contain; background-position:center;
  margin-left:4px; width:12px; height:12px; background-image:url(@/assets/icon/icon-11.svg);
}
.BTN-TYPE-1{ --btn-hei:32px; }
.BTN-TYPE-1{ display:inline-block; background-color:rgba(0, 0, 0, 1); padding-left:calc(var(--btn-hei) / 2); padding-right:calc(var(--btn-hei) / 2); border-radius:calc(var(--btn-hei) / 2); }
.BTN-TYPE-1{ font-weight:500; font-size:14px; line-height:32px; }
.BTN-TYPE-1:after{
  display:inline-block; content:""; vertical-align:middle; background-repeat:no-repeat; background-size:contain; background-position:center;
  margin-left:4px; width:12px; height:12px; background-image:url(@/assets/icon/icon-11.svg);
}
/**/
.SELECT{
  display:inline-block; height:50px; padding-left:18px; padding-right:18px;
  font-weight:500; font-size:14px; line-height:16px; color:var(--neutral-90);
}
/**/
.INPUT_BOX{
  border-bottom:1px solid white; padding-bottom:7px;
}
.INPUT_BOX.WRANING{
  border-bottom-color:var(--form-wran);
}
.INPUT_BOX > label{
  font-weight:600; font-size:12px; line-height:14px; letter-spacing:2px; color:white;
  display:block; width:100%;
}
.INPUT_BOX.WRANING > label{
  color:var(--form-wran);
}

.INPUT_BOX > label + .INPUT{
  margin-top:6px; outline:none;
  font-weight:400; font-size:16px; line-height:28px; color:white; background-color:transparent;
  display:block; width:100%;
  appearance:none;
}

.INPUT_BOX > label + input[type=number]::-webkit-outer-spin-button,
.INPUT_BOX > label + input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.INPUT_BOX.WRANING > label + .INPUT{
  color:var(--form-wran);
}

.INPUT_BOX > label + .INPUT::placeholder{
  color:var(--neutral-70);
}

/**/
/* 2023-01-12 :: START :: KSM : ITEM-0112 ANIMATION */
.ITEM-0112[data-direction]{}
.ITEM-0112[data-direction] .BG{
  animation-duration:300ms; /* time|initial|inherit; */
  animation-iteration-count:1; /* number|infinite|initial|inherit; */
  animation-fill-mode:forwards; /* none|forwards|backwards|both|initial|inherit; */
  animation-timing-function:cubic-bezier(0.215, 0.610, 0.355, 1.000); /* linear|ease|ease-in|ease-out|cubic-bezier(n,n,n,n)|initial|inherit;*/
}
.ITEM-0112[data-direction="TOP_IN"] .BG{top:0; left:0; width:100%;animation-name:TOP_IN;}
.ITEM-0112[data-direction="TOP_OUT"] .BG{top:0; left:0; width:100%;animation-name:TOP_OUT;}
.ITEM-0112[data-direction="RIGHT_IN"] .BG{top:0; right:0; height:100%;animation-name:RIGHT_IN;}
.ITEM-0112[data-direction="RIGHT_OUT"] .BG{top:0; right:0; height:100%;animation-name:RIGHT_OUT;}
.ITEM-0112[data-direction="BOTTOM_IN"] .BG{bottom:0; left:0; width:100%;animation-name:BOTTOM_IN;}
.ITEM-0112[data-direction="BOTTOM_OUT"] .BG{bottom:0; left:0; width:100%;animation-name:BOTTOM_OUT;}
.ITEM-0112[data-direction="LEFT_IN"] .BG{top:0; left:0; height:100%;animation-name:LEFT_IN;}
.ITEM-0112[data-direction="LEFT_OUT"] .BG{top:0; left:0; height:100%;animation-name:LEFT_OUT;}
@keyframes TOP_IN{
  from{height:0%;}
  to{height:100%;}
}
@keyframes TOP_OUT{
  from{height:100%;}
  to{height:0%;}
}
@keyframes RIGHT_IN{
  from{width:0%;}
  to{width:100%;}
}
@keyframes RIGHT_OUT{
  from{width:100%;}
  to{width:0%;}
}
@keyframes BOTTOM_IN{
  from{height:0%;}
  to{height:100%;}
}
@keyframes BOTTOM_OUT{
  from{height:100%;}
  to{height:0%;}
}
@keyframes LEFT_IN{
  from{width:0%;}
  to{width:100%;}
}
@keyframes LEFT_OUT{
  from{width:100%;}
  to{width:0%;}
}
/* // 2023-01-12 :: END :: KSM : ITEM-0112 ANIMATION */

/* 2023-01-14 :: START :: KSM : LAYER_POP */
.LAYER_POP{ z-index:200; position:fixed; }
.LAYER_POP{ width:100%; height:100%; }
.LAYER_POP{ display:flex; align-items:center; justify-content:center; }
.LAYER_POP{ top:0; left:-100%; }
.LAYER_POP{ transition:all 300ms cubic-bezier(0.215, 0.610, 0.355, 1.000); }
.LAYER_POP.ACTIVE{ top:0; left:0; }
.LAYER_POP > .BG{ z-index:0; position:absolute; top:0px; left:0px; width:100%; height:100%; background-color:rgba(0, 0, 0, .7); }
.LAYER_POP > .INNER{ position:relative; z-index:10; overflow:auto; }
.LAYER_POP > .INNER{ height:100vh; }
.LAYER_POP > .INNER{ background-color:#1a1a1f; color:white; }
.LAYER_POP > .INNER{ padding-top:84px; padding-bottom:100px; padding-left:20px; padding-right:20px; }
.LAYER_POP > .INNER::-webkit-scrollbar{ width:4px; height:4px; }
.LAYER_POP > .INNER::-webkit-scrollbar-button:start:decrement,
.LAYER_POP > .INNER::-webkit-scrollbar-button:end:increment{ display:block; width:0px; height:0px; }
.LAYER_POP > .INNER::-webkit-scrollbar-track{ background:rgba(255, 255, 255, .1); border-radius:4px; }
.LAYER_POP > .INNER::-webkit-scrollbar-thumb{ background:rgba(255, 255, 255, .3); border-radius:4px; }
.LAYER_POP > .INNER > .HEAD{ position:relative; }
.LAYER_POP > .INNER > .HEAD > .CLOSE_BTN{ z-index:0; position:absolute; top:-10px; right:0px; }
.LAYER_POP > .INNER > .HEAD > .CLOSE_BTN{ width:24px; height:24px; background-image:url(/src/assets/icon/icon-09.svg); }
/* md 화면*/
@media screen and (min-width:768px){
  .LAYER_POP{ transition:none; }
  .LAYER_POP > .INNER{ height:90vh; }
  .LAYER_POP > .INNER{ padding-top:64px; padding-bottom:91px; padding-left:95px; padding-right:95px; }
  .LAYER_POP > .INNER > .HEAD > .CLOSE_BTN{ display:none; }
}
/* // 2023-01-14 :: END :: KSM : LAYER_POP */

.C-TITLE-1{
  font-weight:600;
  font-size:16px;
  line-height:24px;
  color:#5F5F62;
}
.C-STRONG-1{
  font-weight:600;
  font-size:16px;
  line-height:24px;
  color:#5F5F62;
}
.C-TEXT-1{
  font-weight:300;
  font-size:16px;
  line-height:26px;
  color:#5F5F62;
}



/******************************************

*******************************************/
/* sm */
@media screen and (min-width:640px){

}

/* md */
@media screen and (min-width:768px){

}

/* lg */
@media screen and (min-width:1024px){

}

/* xl 이상 화면*/
@media screen and (min-width:1280px){
}

